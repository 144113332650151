// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import languageStrings from '../../../language/index';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function Partners() {
  const options = {
    items: 1,
    loop: true,
    // nav: true,
    margin: 80,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 4,
      },
    },
  };
  return (
    <>
      <section className="Partners">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-dash-12 text-center">
              <h4 className="whiteheadingtwo mb-5">
                {languageStrings['Featured In']}
              </h4>
            </div>
            
          </div>
          <div className="row">
            {/* <OwlCarousel className="owl-theme" {...options}>
              <div className="item">
                <img
                  src="/assets/imgs/Image-20.png"
                  className="w-100"
           
                />
              </div>
              <div className="item">
                <img
                  src="/assets/imgs/Image-21.png"
                  className="w-100"
                  
                />
              </div>
              <div className="item">
                <img
                  src="/assets/imgs/Image-22.png"
                  className="w-100"
                  
                />
              </div>
              <div className="item">
                <img
                  src="/assets/imgs/Image-23.png"
                  className="w-100"
                  
                />
              </div>
              <div className="item">
                <img
                  src="/assets/imgs/Image-24.png"
                  className="w-100"
                 
                />
              </div>
              <div className="item">
                <img
                  src="/assets/imgs/Image-25.png"
                  className="w-100"
                 
                />
              </div>
            </OwlCarousel> */}
            <div style={{display:'flex',alignItems:"center",flexWrap:"wrap",justifyContent:"center"}}>
                <img
                  
                  src="/assets/imgs/Image-20.png"
                  className="mr-4"
                  style={{margin:"20px",width:"125px",cursor: 'pointer'}}
                  onClick={() => {
                    //window.open('https://google.com', "_blank")//morning star
                  }}
                  //style={{ height: "70px", objectFit: "contain" }}
                />
                <img
                  src="/assets/imgs/Image-21.png"
                  className=" mr-4"
                  style={{margin:"20px",width:"125px",cursor: 'pointer'}}
                  onClick={() => {
                    // window.open('https://google.com', "_blank")
                  }}
                  // style={{ height: "70px", objectFit: "contain" }}
                />
                <img
                  src="/assets/imgs/Image-22.png"
                  className=" mr-4"
                  style={{margin:"20px",width:"125px",cursor: 'pointer'}}
                  onClick={() => {
                    // window.open('https://google.com', "_blank")
                  }}
                  // style={{ height: "70px", objectFit: "contain" }}
                />
                 <img
                  src="/assets/imgs/Image-23.png"
                  className=" mr-4"
                  style={{margin:"20px",width:"125px",cursor: 'pointer'}}
                  onClick={() => {
                    // window.open('https://google.com', "_blank")
                  }}
                  // style={{ height: "70px", objectFit: "contain" }}
                />
                <img
                  src="/assets/imgs/Image-24.png"
                  className=" mr-4"
                  style={{margin:"20px",width:"125px",cursor: 'pointer'}}
                  onClick={() => {
                    // window.open('https://google.com', "_blank")
                  }}
                  // style={{ height: "70px", objectFit: "contain" }}
                />
                 <img
                  src="/assets/imgs/Image-25.png"
                  className=" mr-4"
                  style={{margin:"20px",width:"125px",cursor: 'pointer'}}
                  onClick={() => {
                    // window.open('https://google.com', "_blank")
                  }}
                  // style={{ height: "70px", objectFit: "contain" }}
                />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
