import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from 'react-toastify';
import languageStrings from '../../language/index';

const PaymentProceed = (props) => {

  const { payload: { amountToPurchase, wallets }, receipt, confirmPurchase, isLoading, currencyDetails: { name, symbol }, isHashReceived, setIsConfirmedFalse } = props;
  const [ privacyPolicy, setPrivacyPolicy ] = useState(false);

  return (
    <>
      <div className="inner-main">
        <div className="row">
          <div className="col-lg-12 col-lan-12 cus">
            <h1 className="main-heading">
            <span onClick={() => {
              setIsConfirmedFalse();
            }} style={{ fontSize: '29px', cursor: 'pointer' }}>{"⬅"}</span>  {languageStrings['Payment Initiated']} {" "}
            </h1>
            <h2 className="p-sub-heading">{languageStrings['Hi!']}</h2>
            <p className="p-des">
              {languageStrings['Your payment for']} <span style={{ color: "#00A9FE" }}>{Number.isNaN(wallets?.[symbol]?.tokenAgainstOneCoin || 0) * (amountToPurchase || 0) ? 0 : ( (amountToPurchase || 0) / (wallets?.[symbol]?.tokenAgainstOneCoin || 0)).toFixed(8)}</span>{" "}
              {symbol} {languageStrings['against']} <span style={{ color: "#00A9FE" }}>{amountToPurchase}</span> {languageStrings['EUCC is under processed']}
            </p>
            <p className="p-des" style={{ color: "#00A9FE" }}>
              { isLoading && languageStrings['Please wait...']}
            </p>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: isLoading ? '25%' : isHashReceived && !receipt?.hash ? '50%' : receipt?.hash ? '100%' : "0%" }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            {
              !receipt?.hash && (
                <div className="d-flex align-item-center">
                  <input checked={privacyPolicy} onClick={() => {
                    setPrivacyPolicy(!privacyPolicy);
                  }} className="checkbox" type="checkbox" />
                  <p className="p-des">
                    {languageStrings['I agree to the Terms & Conditions and Privacy Policy']}
                  </p>
                </div>
              )
            }
            {
              !receipt.hash && <div className="mt-5 mb-4">
              <a style={{ cursor: 'pointer' }} onClick={(e) => {
                e.preventDefault();
                if(isLoading) return;

                if(!privacyPolicy) {
                  toast.info('Please accept the privacy & policy terms');
                  return;
                }
                confirmPurchase(e);
              }} className="confirm-b">
                {languageStrings['Confirm']} {"  "} { isLoading && <Spinner style={{ width: '20px', height: '20px' }} /> }
              </a>
            </div>}
              {
                receipt?.hash && <React.Fragment> 
                  <div>
                    <p className="hash">{languageStrings['Transaction Hash Received:']}{" "}<br className="break"/>{"    "}<a className="" href={`${process.env.REACT_APP_EXPLORER}/tx/${receipt?.hash}`} target="_blank">{receipt?.hash}</a></p>
                    </div>
                    <div className="succeed">
                      <h1>{languageStrings['Sent']} <span style={{color:"#00A9FE"}}> {(( (amountToPurchase || 0) / (wallets?.[symbol]?.tokenAgainstOneCoin || 0) )).toFixed(8)} {symbol}</span></h1>
                      <p>{languageStrings['to']}<br className="break"/> {wallets?.[symbol]?.wallet}</p>
                      <div className="inner-succeed">
                        <div className="d-flex justify-content-between align-items-center mb-3  proo flex-wrap">
                          <h2>{languageStrings['From Address']}</h2>
                          <p>{receipt?.from}</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3 proo flex-wrap">
                          <h2>{languageStrings['EUCC Coin Transaction Hash']}</h2>
                          <p>
                            <a className="" href={`${process.env.REACT_APP_EXPLORER}/tx/${receipt?.hash}`} target="_blank">{receipt?.hash}</a>
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3 proo flex-wrap">
                          <h2>{languageStrings['EUCC Coin Amount']}</h2>
                          <p>{amountToPurchase}</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3 proo flex-wrap">
                          <h2>{languageStrings['Verified']}</h2>
                          <div className="status">
                            <p>{languageStrings['Succeed']}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                </React.Fragment>
              }
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentProceed;
