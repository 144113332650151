import invoke from "../utils/invoke";

export const getWalletListApi = () => {
    return invoke({ 
        url: `v1/api/wallets/getWallets`
     })
}

export const getWalletByCurrencyApi = (symbol = 'ETH') => {
    return invoke({ 
        url: `v1/api/wallets/wallet/${symbol}`
     })
}

export const getEuccWalletBalance = (address) => {
    return invoke({ 
        url: `v1/api/wallets/eucc/balance/${address}`
     })
}