import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { getWalletListApi } from "../../api/wallet";
import { submitPaymentApi } from '../../api/payments';
import { useAccount, useContractWrite, useNetwork, usePublicClient, useWalletClient } from 'wagmi'
import util, { isSupportedChain, isEth, isBnb } from '../../utils/util';
import { toast } from 'react-toastify';
import { BigNumber, ethers } from 'ethers';
import { Spinner } from "react-bootstrap";
import PaymentProceed from "./PaymentProceed";
import languageStrings from '../../language/index';
import usdt from '../../contracts/usdt';
import { parseEther, parseUnits } from "viem";

let sourceAmount = '';

export default () => {

  const location = useLocation();
  const walletClient = useWalletClient();

  

  const { address, isConnected } = useAccount();
  const wallet = useWalletClient();
  
  const { chain: { id, name, nativeCurrency: { symbol } } } = useNetwork();

  const { writeAsync } = useContractWrite({ address: usdt.address[id], abi: usdt.abi , functionName: 'transfer', chainId: id });
  
  const [ state, setState ] = useState({ amountToPurchase: '', wallets: {} });
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isConfirmed, setIsConfirmed ] = useState(false);
  const [ receipt, setReceipt ] = useState({});
  const [ isHashReceived, setIsHashReceived ] = useState(false);
  const [ currencyDetails, setCurrencyDetails ] = useState({ symbol: symbol, name: name });

  useEffect(() => {
    setCurrencyDetails({ name, symbol });
    if(!isSupportedChain(id)) setIsConfirmed(false);
  }, [symbol, id])

  useEffect(() => {
    if(location.search.length > 12) {
      const [ key, value ] = location.search.replace('?', '').split('=');
      if(key === 'sourceAmount') sourceAmount = value;
    }
  }, [])


  const onAmountToPurchaseChangeListener = e => {

    e.preventDefault && e.preventDefault();
    
    if(e.target.value === '' || e.target.value === '0') {
      setState({ ...state, amountToPurchase: e.target.value })
    }

    if(!util.isNumber(e.target.value)) return;

    setState({ ...state, amountToPurchase: e.target.value })
  }

  useEffect(() => {
    getWalletListApi().then(data => {
      const wallets = {};
      data.forEach((element, index) => {
        wallets[element.symbol] = element
      });
      setState({ ...state, wallets });
    })
  }, []);

  useEffect(() => {
    if(state.wallets.ETH) onAmountToPurchaseChangeListener({ target: { value: sourceAmount } });
  }, [state.wallets.ETH]);

  window.parseEther = parseEther;
  window.writeAsync = writeAsync;
  const confirmPurchase = async () => {

    setIsLoading(true);

    try {
      
      const _receipt = currencyDetails.symbol === 'USDT' ? await walletClient.data.writeContract({ abi: usdt.abi, address: usdt.address[id], functionName: 'transfer', args: [ state.wallets?.[currencyDetails.symbol]?.wallet, parseUnits((state.amountToPurchase   / state.wallets?.[currencyDetails.symbol]?.tokenAgainstOneCoin).toString(), 6) ]  }) : await wallet.data.sendTransaction({
        to: state.wallets?.[currencyDetails.symbol]?.wallet,
        value: ethers.utils.parseEther(((state.amountToPurchase || 0) / (state.wallets?.[currencyDetails.symbol]?.tokenAgainstOneCoin || 0)).toFixed(8))
      });
      setIsHashReceived(true);
      await submitPaymentApi({ transactionHash: _receipt, currencySymbol: currencyDetails.symbol, depositWallet: address, transactionAmount: ((state.wallets?.[currencyDetails.symbol]?.tokenAgainstOneCoin || 0) * (state.amountToPurchase || 0)).toFixed(8), tokenTransferAmount: state.amountToPurchase });
      toast.success('Transaction successful', { 
        position: toast.POSITION.TOP_RIGHT,
      });
      setReceipt({ hash: _receipt, from: address });
    } catch(e) {
      console.log(e);
      toast.info(/insufficient funds/.test(e.message) ? 'Insufficient funds' : /User rejected the request/.test(e.message) ? 'User declined the transaction' : /ERC20: transfer amount exceeds balance/.test(e.message) ? 'USDT balance is too low to proceed' : e.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setIsLoading(false);
  }

  const initiatePayment = async (e) => {
    if(!isSupportedChain(id)) {
      toast.info('You are not connected to supported Ethereum or Binance chain.')
      return;
    }

    if(!isConnected) {
      toast.info('You are not connected!');
      return;
    }

    if(state.amountToPurchase < 0.0001) {
      toast.info('Amount should be greater then 0.0001');
      return;
    }

    setIsConfirmed(true);
  }

  if(isConfirmed) return <PaymentProceed setIsConfirmedFalse={() => {
    setIsConfirmed(false);
  }} isHashReceived={isHashReceived} payload={state} confirmPurchase={confirmPurchase} currencyDetails={currencyDetails} receipt={receipt} isLoading={isLoading} />

  return (
    <div className="inner-main">
      <div className="col-lg-12  col-lan-12">
        <h1 className="main-heading">{languageStrings['Purchase Coin']}</h1>
        <h2 className="sub-heading"> {languageStrings['Address to receive coin']}</h2>
        <div className="token-bar">
          <p>{address}</p>
        </div>
        <div className="row pt-2">
          <div className="col-lg-5  col-lan-5">
            <div className="d-flex align-items-center input-a">
              <input value={state.amountToPurchase} onChange={onAmountToPurchaseChangeListener} style={{ color: 'white' }} className="" placeholder={languageStrings['Amount of coin to buy']}></input>
              <p>EUCC</p>
            </div>
          </div>
          <div className="col-lg-7  col-lan-7">
          <div className="select">
          <h4>{languageStrings['Or Select an amount']}</h4>
        </div>
        <div className="row">
          <div onClick={(e) => {
            e.target.value = '2000';
            onAmountToPurchaseChangeListener(e);
          }} className="col-lg-4  col-lan-4">
            <div style={{ cursor: 'pointer', backgroundColor: state.amountToPurchase == '2000' ? '#00AAFF' : '' }} className="amount-num">2000</div>
          </div>
          <div onClick={(e) => {
            e.target.value = '4000';
            onAmountToPurchaseChangeListener(e);
          }} className="col-lg-4  col-lan-4">
            <div style={{ cursor: 'pointer', backgroundColor: state.amountToPurchase == '4000' ? '#00AAFF' : '' }} className="amount-num">4000</div>
          </div>
          <div onClick={(e) => {
            e.target.value = '6000';
            onAmountToPurchaseChangeListener(e);
          }} className="col-lg-4 col-lan-4">
            <div style={{ cursor: 'pointer', backgroundColor: state.amountToPurchase == '6000' ? '#00AAFF' : '' }} className="amount-num">6000</div>
          </div>
          </div>
          </div>
        </div>
        <div className="row pt-5 md-pt-5 swap ">
          <div className="col-lg-6  col-lan-6">
            <div className="swap">
              <img src="/assets/imgs/swaptoken.png" alt="swaptoken" />
            </div>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <div className="row pt-5 pt-md-2">
            <div className="col-lg-6 col-lan-6">
              <div className={`pay`} onClick={() => {
                setCurrencyDetails({ symbol: symbol, name: name });
              }} style={{ cursor: !isSupportedChain(id) ? 'not-allowed' : 'pointer', color: !isEth(id) ? '#808080b8' : currencyDetails.symbol === 'ETH' || currencyDetails.symbol === 'SEP' ? '#00AAFF' : 'white', border: currencyDetails.symbol === 'ETH' || currencyDetails.symbol === 'SEP' ? '1px solid #0AF' : '' }}>
                <h6>{languageStrings['PAY']} {Number.isNaN( state.wallets?.ETH?.tokenAgainstOneCoin || 0) / (state.amountToPurchase || 0) ? 0 : ( (state.amountToPurchase || 0) / (state.wallets?.ETH?.tokenAgainstOneCoin || 0) ).toFixed(8)}</h6>
                <h6>ETH</h6>
              </div>
              <div className={`pay`} style={{ cursor: !isSupportedChain(id) ? 'not-allowed' : 'pointer', color: !isBnb(id) ? '#808080b8' : '#00AAFF', border: isBnb(id) ? '1px solid #0AF' : '' }}>
                <h6>{languageStrings['PAY']} {Number.isNaN(state.wallets?.BNB?.tokenAgainstOneCoin || 0) / (state.amountToPurchase || 0) ? 0 : ( (state.amountToPurchase || 0) / (state.wallets?.BNB?.tokenAgainstOneCoin || 0) ).toFixed(8) }</h6>
                <h6>BNB</h6>
              </div>
              <div className={`pay`} onClick={() => {
                setCurrencyDetails({ symbol: 'USDT', name: 'USDT' });
              }} style={{ cursor: !isSupportedChain(id) ? 'not-allowed' : 'pointer', color: !isEth(id) ? '#808080b8' : currencyDetails.symbol === 'USDT' ? '#00AAFF' : 'white', border: currencyDetails.symbol === 'USDT' ? '1px solid #0AF' : '' }}>
                <h6>{languageStrings['PAY']} {Number.isNaN(state.wallets?.USDT?.tokenAgainstOneCoin || 0) / (state.amountToPurchase || 0) ? 0 : ( (state.amountToPurchase || 0) / (state.wallets?.USDT?.tokenAgainstOneCoin || 0)).toFixed(8)}</h6>
                <h6>USDT</h6>
              </div>
            </div>
            <div className="col-lg-6  col-lan-6">
              <div title={!isSupportedChain(id) ? 'You are not connected to a supported chain' : 'Confirm Purchase'} className="confirm-main">
                <h6>{languageStrings['Confirm to purchase']} { isLoading && <Spinner style={{ width: '20px', height: '20px' }} /> }</h6>
                <a disabled={isLoading || !isSupportedChain(id)} style={{ cursor: 'pointer' }} onClick={initiatePayment}>{languageStrings['Confirm']}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}