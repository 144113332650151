import Countdown from "react-countdown";
import { useEffect, useState } from "react";
import { getWalletListApi } from '../../../api/wallet';
import { getPreSaleEndDateApi } from '../../../api/settings';
import { Link } from "react-router-dom";
import languageStrings from '../../../language/index';
import { useWeb3Modal } from '@web3modal/react';
import { useAccount, useContract, useConnect, useDisconnect } from 'wagmi';

export default function Sale() {

  const [ state, setState ] = useState({ userBalance: 0, selectedWallet: {}, wallets: {}, sourceAmount: null, targetAmount: null });
  const { open, close } = useWeb3Modal();
  const { isConnected } = useAccount();
  const [ presaleEndDate, setPresaleEndDate ] = useState(null);

  useEffect(() => {
    getWalletListApi().then(data => {
      const wallets = {};
      let selectedWallet = {};
      data.forEach((element, index) => {
        if(index === 0) selectedWallet = element;
        wallets[element.symbol] = element
      });
      setState({ ...state, wallets, selectedWallet });
    })

    getPreSaleEndDateApi().then(({ data }) => {
      setPresaleEndDate(data)
    }).catch(e => {})

  }, []);

  useEffect(() => {
    if(state.selectedWallet.symbol) onChangeListener({ target: { value: state.sourceAmount } });
  }, [state.selectedWallet.symbol])

  const onWalletSelection = (symbol) => {
    setState({ ...state, selectedWallet: state.wallets[symbol] });
  }

  const onChangeListener = e => {
    e.preventDefault && e.preventDefault();
    
    if(e.target.value === '' || e.target.value === '0') {
      setState({ ...state, sourceAmount: '', targetAmount: '' })
    }

    const sourceAmount = e.target.value; //parseFloat(e.target.value);
    // if(sourceAmount <= 0 ) return;

    setState({ ...state, sourceAmount, targetAmount: Number.isNaN(sourceAmount * state.selectedWallet.tokenAgainstOneCoin) ? state.targetAmount : (sourceAmount * state.selectedWallet.tokenAgainstOneCoin ).toFixed(3) })
  }

  const Completionist = () => <span>You are good to go!</span>;
  
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      return (
        <div className="d-flex mt-4 countdown justify-content-center">
          <div>
            <h1>{days}</h1>
            <h4>{languageStrings['Days']}</h4>
          </div>
          <div>
            <h1>{hours}</h1>
            <h4>{languageStrings['Hours']}</h4>
          </div>
          <div>
            <h1>{minutes}</h1>
            <h4>{languageStrings['Minutes']}</h4>
          </div>
          <div>
            <h1>{seconds}</h1>
            <h4>{languageStrings['Seconds']}</h4>
          </div>
        </div>
      );
    }
  };
  return (
    <>
    <Link id="navigate-to-purchase" style={{ display: 'none' }} to={state.sourceAmount ? `/dashboard/purchase-token?sourceAmount=${state.targetAmount || 0}` : '/dashboard/purchase-token'}/>
      <section id="sale-section" className="Sale d-flex align-items-center position-relative">
        <div className="hotsalebg">
          <div className="anim1">
            <img
              src="/assets/images/floating3.png"
              className="avatar position-absolute"
              style={{ top: "10%", left: "10%" }}
              alt=""
            />
            <img
              src="/assets/images/floating1.png"
              className="avatartwo position-absolute"
              style={{ top: "30%", left: "54%" }}
              alt=""
            />

            <img
              src="/assets/images/floating2.png"
              className="avatarthree position-absolute"
              style={{ top: "30%", left: "90%" }}
              alt=""
            />
          </div>
          <div className="container ">
            <div className="">
              <div className="row align-items-center">
                <div className="col-lg-7 col-dash-7">
                  <div>
                    <div
                      className="content "
                      style={{ zIndex: "9999", marginBottom: "40px" }}
                    >
                      <h4 className="blueheading m-0">{languageStrings['ESTATE UPCARE COIN']}</h4>
                      <h5 className="whiteheading mb-2">
                        {languageStrings['CRYPTO BACKED BY REAL ESTATE, INFRASTRUCTURE, CONSTRUCTION']}
                      </h5>
                      <p className="description m-0">
                        {languageStrings['Estate upcare is a blockchain ecosystem']}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-dash-5">
                  <div>
                    <div className="banner banner-sale text-center">
                      {/* <img src="/assets/images/presell.png" className="w-100" /> */}
                      <div className="salecontent">
                        <div className="d-flex align-items-center mb-4 justify-content-center">
                          <span>
                            <img src="/assets/images/fire.png" alt="" />
                          </span>
                          <h4 className="blueheadingtwo m-0">{languageStrings['HOT SALE']}</h4>
                          <span>
                            <img src="/assets/images/fire.png" alt="" />
                          </span>
                        </div>
                        <h3
                          style={{ fontSize: '30px' }}
                          className="whiteheadingtwo whiteheadingtwob m-3"
                        >
                          {languageStrings['Presale End In']}
                        </h3>
                        <h4
                          className="whiteheadingtwo whiteheadingtwos mt-2"
                          style={{ fontSize: "20px" }}
                        >
                          {languageStrings['Pre-Sale Price 1 EUCC = $0.1']}
                        </h4> 
                          <Countdown
                            date={new Date(presaleEndDate || (Date.now() + 555500000)).getTime()}
                            renderer={renderer}
                          />
                        <span
                          style={{ fontSize: "16px", fontWeight: "500" }}
                          className="d-block mb-4 mt-4"
                        >
                          {languageStrings['Listing price']}: $0.1
                        </span>
                        <ul className="d-flex coins justify-content-center list-inline">
                          <li onClick={(e) => {
                            e.preventDefault();
                            onWalletSelection('ETH');
                          }} className="eth-list" style={{ width: "100px", backgroundColor: state.wallets.ETH ? 'white' : '#b9b9b9', cursor: state.wallets.ETH ? 'pointer' : 'not-allowed' }}>
                            ETH
                            <span>
                              <img src="/assets/images/et.png" alt="" />
                            </span>
                          </li>
                          <li onClick={(e) => {
                            e.preventDefault();
                            onWalletSelection('USDT');
                          }} className="eth-list" style={{ width: "112px", backgroundColor: state.wallets.USDT ? 'white' : '#b9b9b9', cursor: state.wallets.USDT ? 'pointer' : 'not-allowed' }}>
                            USDT
                            <span>
                              <img src="/assets/images/ustd.png" alt="" />
                            </span>
                          </li>
                          <li onClick={(e) => {
                            e.preventDefault();
                            onWalletSelection('BNB');
                          }} className="eth-list" style={{ width: "103px", backgroundColor: state.wallets.BNB ? 'white' : '#b9b9b9', cursor: state.wallets.BNB ? 'pointer' : 'not-allowed' }}>
                            BNB
                            <span>
                              <img src="/assets/images/bnb-b.png" alt="" />
                            </span>
                          </li>
                          <li className="eth-list" style={{ width: "117px", backgroundColor: state.wallets.CARD ? 'white' : '#b9b9b9', cursor: state.wallets.CARD ? 'pointer' : 'not-allowed' }}>
                            CARD
                            <span>
                              <img src="/assets/images/credit-card.png" alt="" />
                            </span>
                          </li>
                        </ul>

                        <div
                          className="d-flex justify-content-between mb-1"
                          style={{
                            color: "#fff",
                            fontSize: "12px",
                            margin: "0px 5px 10px 5px",
                            fontWeight: "500",
                          }}
                        >
                          <div>{languageStrings['Amount in']} {state.selectedWallet.symbol} {languageStrings['you pay']}</div>
                          <div>{languageStrings['MAX']}</div>
                        </div>

                        <div
                          className="input-group flex-nowrap"
                          style={{
                            margin: "0px 5px 20px 5px",
                            paddingRight: "10px",
                          }}
                        >
                          <input
                            type="text"
                            className="form-control cus-input"
                            value={state.sourceAmount}
                            placeholder="0"
                            onChange={onChangeListener}
                            aria-describedby="addon-wrapping"
                            style={{ height: "50px" ,marginTop:'0px' }}
                          />
                          <span
                            className="input-group-text"
                            style={{ padding: "0px 20px"}}
                            id="addon-wrapping"
                          >
                            <img src={`/assets/images/${state.selectedWallet.symbol === 'ETH' ? 'et' : state.selectedWallet.symbol === 'USDT' ? 'ustd' : state.selectedWallet.symbol  === 'BNB' ? 'bnb-b' : state.selectedWallet.symbol === 'CARD' ? 'credit-card' : 'et'  }.png`} alt="" />
                          </span>
                        </div>

                        <div
                          className="d-flex justify-content-between"
                          style={{
                            color: "#fff",
                            fontSize: "12px",
                            margin: "0px 5px 4px 5px",
                            fontWeight: "500",
                          }}
                        >
                          <div>{languageStrings['Amount in']} EUCC {languageStrings['you received']}</div>
                        </div>

                        <div
                          className="input-group flex-nowrap"
                          style={{
                            margin: "0px 5px 10px 5px",
                            paddingRight: "10px",
                          }}
                        >
                          <input
                            type="text"
                            className="form-control cus-input"
                            aria-label=""
                            aria-describedby="addon-wrapping"
                            style={{ height: "50px",marginTop:'0px' }}
                            value={state.targetAmount}
                            placeholder="0"
                            readOnly
                          />
                          <span
                            className="input-group-text"
                            style={{ padding: "0px 20px" }}
                            id="addon-wrapping"
                          >
                            <img src="/assets/images/to.png" alt="" />
                          </span>
                        </div>
                        <span
                          className="d-block mt-4 mb-3"
                          style={{ fontSize: "12px", fontWeight: "500", marginLeft: "60px", marginRight: "60px" }}
                        >
                          {/* 0.1 ETH {languageStrings['is reserved for gas. The actual amount used will depend on the network.']} */}
                        </span>
                        {
                          !isConnected ? (
                            <a onClick={(e) => {
                              open();
                            }} className="btn mb-1 btn-custom">
                              {languageStrings['Connect wallet']}
                            </a>
                          ) : (
                            <a onClick={(e) => {
                              document.getElementById('navigate-to-purchase').click();
                            }} className="btn mb-1 btn-custom">
                              {languageStrings['Start Purchase']}
                            </a>
                          )
                        }
                        <a id="nav-to-guide" style={{ display: 'none' }} href="/assets/guide/connection-guide.pdf" target="_blank"></a>
                        <span onClick={() => {
                          document.getElementById('nav-to-guide').click();
                        }} style={{ cursor: 'pointer' }} className="d-block mt-4 mb-1">{languageStrings['Are you facing issues connecting with Trust Wallet Extension?']}</span>
                        <a id="nav-to-how-to-buy" href="#how-to-buy" style={{ display: 'none' }}></a>
                        <span
                          onClick={() => {
                            document.getElementById('nav-to-how-to-buy').click();
                          }}
                          className="d-block mt-4 mb-1"
                          style={{ fontSize: "12px", fontWeight: "500", cursor: 'pointer' }}
                        >
                          <img
                            src="/assets/images/help.png"
                            className="me-2"
                            alt=""
                          />
                          {languageStrings['How to buy']}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ConnectWallet /> */}
      </section>
    </>
  );
}
