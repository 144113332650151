import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import languageStrings from '../../language/index';
import { useAccount, useDisconnect } from 'wagmi';
import { getEuccWalletBalance } from "../../api/wallet";

const Topbar = () => {

  const [ balance, setBalance ] = useState(null);
  const { address } = useAccount();

  const fetchBalance = () => {
    if(address) getEuccWalletBalance(address).then((data) => {
      setBalance(data.balance);
    }).catch(e => {});
  }


  useEffect(() => {
    fetchBalance();
  }, [ address ]);


  const { disconnect } = useDisconnect();

  return (
    <div>
      <div className="topbar">
          <ul className="">
            <li>
              <a href="/#about-us">{languageStrings['Why Choose Coin']}</a>
            </li>
            <li>
              <a href="/#our-team">{languageStrings['Our Team']}</a>
            </li>
            <li>
              <a href="/#sale-section">{languageStrings['Estate Upcare coin']}</a>
            </li>
            <li>
              <a href="/#sale-section">{languageStrings['Coin Sale Stage']}</a>
            </li>
            
          </ul>
          <li className="pull-right" style={{ float: 'right'}}>
            <strong><a style={{ color: 'white' }} >Balance: {balance} EUCC</a></strong>
          </li>
          <div className="button-sec">
            <div>
              <a style={{ cursor: 'pointer' }}  className="buy-b" onClick={async () => {
                disconnect();
              }}>{languageStrings['Disconnect']}</a>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Topbar;
