import react, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from 'react-toastify';
import { useAccount } from 'wagmi'
import { getTransactionsByWalletApi } from "../../api/payments";
import languageStrings from '../../language/index';

let timeInterval = null;

const Transactiondetails = () => {

  const [ transactions, setTransactions ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const { address } = useAccount();


  const fetchTransactionListByWallet = () => {
    if(address)
      getTransactionsByWalletApi(address).then(data => {
        setTransactions(data);
        setIsLoading(false);
      }).catch(e => {
        toast.info(e.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        setIsLoading(false);
      });
  }

  useEffect(() => {
    fetchTransactionListByWallet();
  }, [address]);

  useEffect(() => {
    if(!timeInterval && transactions.findIndex(_ => _.status === 'PENDING') !== -1) {
      timeInterval = setInterval(fetchTransactionListByWallet, 3000);
    }

    return () => {
      if(timeInterval) {
        clearInterval(timeInterval);
        timeInterval = null;
      }
    }

  }, [transactions.length])


  const data = [  {
    to: "John",
    from: "Alice",
    amount: 1000,
    date: "2023-06-15",
    status: "Completed"
  },
  {
    to: "Bob",
    from: "Alice",
    amount: 1500,
    date: "2023-06-16",
    status: "Pending"
  },
  {
    to: "Alice",
    from: "Sarah",
    amount: 500,
    date: "2023-06-17",
    status: "Completed"
  }];
  return (
    <>
      <div className="inner-main">
        <h1 className="main-heading">{languageStrings['Transaction History']} { isLoading && <Spinner style={{ width: '20px', height: '20px' }} />}</h1>
          <div className="table-responsive">
        <table className="table-responsive" style={{ width: "100%", color: "white" }}>

          
          <tbody>
            <tr style={{ backgroundColor: "#00AAFF", borderRadius: "10px" }}>
              <th style={{ padding: "15px" }}>{languageStrings['Tx. Hash']}</th>
              <th style={{ padding: "15px" }}>{languageStrings['From']}</th>
              <th style={{ padding: "15px" }}>{languageStrings['Coin(s) Purchase']}</th>
              <th style={{ padding: "15px" }}>{languageStrings['Coin(s) Receipt']}</th>
              <th style={{ padding: "15px" }}>{languageStrings['Status']}</th>
            </tr>
            {transactions.map((item, index) => {
              const { transactionHash, depositWallet, tokenTransferAmount, tokenTransactionHash, status } = item;
              return (
                <tr key={index}>
                  <td style={{ padding: "15px" }}>
                    <a href={`${process.env.REACT_APP_EXPLORER}/tx/${transactionHash}`} target="_blank"> {transactionHash.slice(0,5)}...{transactionHash.slice(transactionHash.length - 5, transactionHash.length)}</a>
                  </td>
                  <td style={{ padding: "15px" }}>
                    <a href={`${process.env.REACT_APP_EXPLORER}/address/${depositWallet}`} target="_blank"> {depositWallet.slice(0,5)}...{depositWallet.slice(depositWallet.length - 5, depositWallet.length)}</a>
                  </td>
                  <td style={{ padding: "15px" }}>{tokenTransferAmount.toFixed(5)}</td>
                  <td style={{ padding: "15px" }}>{tokenTransactionHash ? <a href={`${process.env.REACT_APP_EXPLORER}/tx/${tokenTransactionHash}`} target="_blank"> {tokenTransactionHash.slice(0,5)}...{tokenTransactionHash.slice(tokenTransactionHash.length - 5, tokenTransactionHash.length)}</a> : 'N/A'}</td>
                  <td style={{ padding: "15px", color: status === 'COMPLETED' ? '#41b741' : 'white' }}>{status === 'COMPLETED' ? languageStrings[status] : ' --- ' }</td>
                </tr>
              )
            })} 
            {
              !transactions.length && !isLoading && <label className="mt-3">{languageStrings['No record to show']}</label>
            }
          </tbody>
         
        </table>
      
        
        </div>
      </div>
    </>
  );
};

export default Transactiondetails;
