import languageStrings from '../../../language/index';
import Accordion from "react-bootstrap/Accordion";

export default function FAQ() {

  return (
    <>
      <section id="faq" className="FAQ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-dash-12">
              <div className="col-lg-12 col-dash-12 text-center">
                <div >
                  <h4 className="whiteheadingtwo mb-2">
                    {languageStrings['Frequently Asked Questions']}
                  </h4>
                  <p className="description mb-5">
                    {languageStrings['Users of crypto websites can face various challenges and problems while interacting with these platforms.']}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-dash-12">
                  <Accordion className="custom-accor" defaultActiveKey="0">
                    <div>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          {languageStrings['How to use EUCC to participate investment in real estate properties globally?']}
                        </Accordion.Header>
                        <Accordion.Body>
                          {languageStrings['FAQ1']}
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                    <div>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          {languageStrings['Is there minimum staking amount for investment?']}
                        </Accordion.Header>
                        <Accordion.Body>
                        {languageStrings['FAQ2']}
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                    <div>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          {languageStrings['What are the advantages of investing real estate properties through Estate Upcare platform compared to the traditional methods?']}
                        </Accordion.Header>
                        <Accordion.Body>
                          {languageStrings['FAQ3'].split('\n').map(_ => <p>{_}<br/><br/></p>)}
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
