import React, { useEffect } from 'react';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';
import { loginApi } from '../../api/auth';
import axios from 'axios';

let isSigning = false;

export default () => {

    const signMessage = useSignMessage({ message: process.env.REACT_APP_METAMASK_PASSPHRASE });
    const { disconnect} = useDisconnect();
    
    axios.interceptors.response.use((response) => {
        if(response.status === 401) {
            try {
                disconnect();
            } catch(e) {}
        }
        return response;
    });

    const loginUser = async () => {
        isSigning = true;
        const signature = await signMessage.signMessageAsync();
        const { token } = await loginApi({ signature, publicAddress: address });
        localStorage.setItem('token', token);
        isSigning = false;
    }

    const { address } = useAccount({
        onConnect: async () => {
            if(isSigning) return;
            try {
                isSigning = true;
                if(localStorage.getItem('token')) return;
                const signature = await signMessage.signMessageAsync();
                const { token } = await loginApi({ signature, publicAddress: address });
                localStorage.setItem('token', token);
            } catch(e) {
                disconnect();
            }
            isSigning = false;
        },
        onDisconnect: () => {
            localStorage.clear();
        }
    });

    // useEffect(() => {
    //     try {
    //         if(!localStorage.getItem('token')) {
    //             loginUser().catch(e => {
    //                 disconnect();
    //                 localStorage.clear();
    //                 isSigning = false;
    //             });
    //         }
    //     } catch(e) {
    //         disconnect();
    //     }
    // }, [ address ]);

    return (
        <React.Fragment></React.Fragment>
    )
}