import languageStrings from '../../../language/index';

export default function RoadMap() {
  return (
    <>
      <section id="road-map" className="RoadMap">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 text-center">
              <h4 className="whiteheadingtwo mb-5">{languageStrings['RoadMap']}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="timeline">
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-right">
                    <div className="marker" />
                    <div className="timeline-content">
                      <div className="data">
                        <h3 className="blueheadingtwor">{languageStrings['July 2023']}</h3>
                        <p className="description">
                          {languageStrings['Website released, whitepaper finalized']}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-left">
                    <div className="timeline-content">
                      <div className="data">
                        <h3 className="blueheadingtwor">{languageStrings['August 2023']}</h3>
                        <p className="description">
                          {languageStrings['Private sale launched, social media accounts kicked off']}
                        </p>
                      </div>
                    </div>
                    <div className="marker" />
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-right">
                    <div className="marker" />
                    <div className="timeline-content">
                      <div className="data">
                        <h3 className="blueheadingtwor">{languageStrings['November 2023']}</h3>
                        <p className="description">
                          {languageStrings['Public sale launched, platform development started']}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-left">
                    <div className="timeline-content">
                      <div className="data">
                        <h3 className="blueheadingtwor">{languageStrings['July 2024']}</h3>
                        <p className="description">
                          {languageStrings['Platform release first real estate projects proposed']}
                        </p>
                      </div>
                    </div>
                    <div className="marker" />
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-right">
                    <div className="marker" />
                    <div className="timeline-content">
                      <div className="data">
                        <h3 className="blueheadingtwor">{languageStrings['Oct 2024']}</h3>
                        <p className="description">
                          {languageStrings['Real estate projects purchased']}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-left">
                    <div className="timeline-content">
                      <div className="data">
                        <h3 className="blueheadingtwor">{languageStrings['Q4 2024']}</h3>
                        <p className="description">
                        {languageStrings['Coins listed on CEX']}
                        </p>
                      </div>
                    </div>
                    <div className="marker" />
                  </div>
                </div>

                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-right">
                    <div className="marker" />
                    <div className="timeline-content">
                      <div className="data">
                        <h3 className="blueheadingtwor">{languageStrings['Q1 2025']}</h3>
                        <p className="description">
                          {languageStrings['Launch on Tier 2 and Tier 1 CEX']}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
