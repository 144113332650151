import invoke from "../utils/invoke";

export const getTransactionsByWalletApi = (address) => {
    return invoke({ 
        url: `v1/api/payments/transactions/${address}`
     })
}

export const submitPaymentApi = (data = {}) => {
    return invoke({ 
        method: 'POST',
        url: `v1/api/payments/submitPayment`,
        data: data,
        headers: { Authorization: localStorage.getItem('token') }
     })
}