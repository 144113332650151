import invoke from "../utils/invoke";

export const getSettingByNameApi = (name = 'contract') => {
    return invoke({ 
        url: `v1/api/settings/${name}`
     })
}

export const getPreSaleEndDateApi = () => {
    return invoke({ 
        url: `v1/api/settings/presale/end/date`
     })
}
