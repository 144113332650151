import axios from 'axios';

const URL = process.env.REACT_APP_BASE_URL;

axios.interceptors.response.use((response) => {
    if(response.status === 401) {
        
    }
    return response;
}, (error) => {
    if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
});

export default function({ method = 'GET', url, data = {}, headers = {} }){
    return axios({ method, url: `${URL}/${url}`, data, headers }).then(({ data }) => data);
}