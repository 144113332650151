import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Mobilemenu from "./Mobilemenu";
import Topbar from "./Topbar";
import './dashboard.css'

import PurchaseToken from "./PurchaseToken";
import { useRouteMatch, Redirect } from "react-router-dom";
import PaymentProceed from "./PaymentProceed";
import Transactiondetails from "./Transactiondetails";
import { useAccount } from 'wagmi';

const Dashboard = () => {

  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const { isConnected } = useAccount();

  const handleResize = () => {
    setIsMobileMenuVisible(window.innerWidth <= 991);
  };

  const router = useRouteMatch();

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if(!isConnected) return <Redirect to="/" />

  return (
    <div id="dashboard-div">
      <Sidebar/>
      {isMobileMenuVisible && <Mobilemenu />}
      <div className="main">
      <Topbar/>  
      {
        router.params.id === 'purchase-token' && <PurchaseToken /> 
      }
      {
        router.params.id  === 'payment' && <PaymentProceed />
      }
      {
        router.params.id  === 'transaction' && <Transactiondetails />
      }
      </div>
    </div>
  );
};

export default Dashboard;
