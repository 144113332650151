export default function GifSlider() {
  return (
    <>
      <section style={{ marginBottom: '-7px', background: 'url(/assets/imgs/logo-placeholder.png)', backgroundSize: 'cover' }} id="platform" className="Slider">
        {/* <video className="w-100" width="320" height="240" controls>
          <source src="/assets/videos/EUCC video .mp4" type="video/mp4"/>
        </video>  */}
        <video loop={true} playsInline={false} autoPlay={true} muted className="w-100" src={`${process.env.REACT_APP_BASE_URL}/v1/api/media/read`}></video>
        {/* <img src="http://159.223.203.41:4000/v1/api/media/read" className="w-100" /> */}
        {/* <img src="/assets/images/slider/slider.gif" className="w-100" /> */}
      </section>
    </>
  );
}
