import React, { useEffect, useState } from "react";
import { useAccount, useDisconnect } from "wagmi";
import languageStrings from '../../language/index';

const Mobilemenu = () => {
  // useEffect(() => {
  //   document.getElementById('dashboard-div').onclick = () => {
  //     setIsOpen(false);
  //   }
  // }, [])

  const [isopen, setIsOpen] = useState(false);

  const handleClick = (e) => {
    setIsOpen(!isopen);
  };
  
  const { isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  return (
    <div className="mobile-menu-main">
      <div className="mobile-nav">
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="d-flex align-items-center justify-content-between"
        >
          <img src="/assets/imgs/dashlogo.png" alt="dashlogo" />
          <img
            style={{ cursor: "pointer" }}
            onClick={handleClick}
            src="/assets/imgs/humber.png"
            alt="humber"
          />
        </div>
        {isopen && (
          <div className="mobile-links">
            <ul className="pt-2">
              <li>
                <a href="#about-us">{languageStrings['Why Choose Coin']}</a>
              </li>
              <li>
                <a href="#our-team">{languageStrings['Our Team']}</a>
              </li>
              <li>
                <a href="#sale-section">{languageStrings['Estate Upcare coin']}</a>
              </li>
              <li>
                <a href="#sale-section">{languageStrings['Coin Sale Stage']}</a>
              </li>
              {
                isConnected && (
                  <li>
                    <a  style={{ cursor: 'pointer', color: 'white' }} onClick={() => disconnect()}>Disconnect</a>
                  </li>
                )
              }
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Mobilemenu;
