import languageStrings from '../../../language/index';

export default function Problems() {

  return (
    <>
      <section className="Problems">
        <div className="container">
          <div className="row pb-3">
            <div className="col-lg-12 col-dash-12 text-center">
              <div>
                <h4 className="whiteheadingtwo mb-2">{languageStrings['Problems']}</h4>
                <p className="description" style={{ marginBottom: "80px",marginBottom:"20px" }}>
                  {languageStrings['One of the significant challenges faced by individuals worldwide is limited access to financial services and profitable investment opportunities.']}
                </p>
              </div>
            </div>
          </div>
          <div
            className="row align-items-center"
            style={{ marginBottom: "80px" }}
          >
            <div className="col-lg-6 col-dash-6">
              <div>
                <div className="content mb-5">
                  <div className="ListCount mb-4">
                    <h3 className="List">01</h3>
                  </div>
                  <h4 className="blueheadingtwop mb-3">{languageStrings['Geographical']} </h4>
                  <p className="description mb-5">
                    {languageStrings['Geographical limitations']}
                  </p>
                  {/* <a href="#" className="btn btn-custom">
                    {languageStrings['Learn More']}
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-dash-6">
              <div>
                <div className="banner ">
                  <img src="/assets/images/geogrpahical.png" alt="" className="w-100" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="row align-items-center"
            style={{ marginBottom: "80px" }}
          >
            <div className="col-lg-6 col-dash-6 order-2 order-md-1">
              <div>
                <div className="banner mb-5">
                  <img
                    src="/assets/images/Accessibility.png"
                    alt=""
                    className="w-100"
                    style={{marginTop:"30px"}}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-dash-6 order-1 order-md-2">
              <div>
                <div className="content">
                  <div className="ListCount mb-4">
                    <h3 className="List">02</h3>
                  </div>
                  <h4 className="blueheadingtwop mb-3">{languageStrings['Accessibility']} </h4>
                  <p className="description mb-5">
                    {languageStrings['This limited access to financial services']}
                  </p>
                  {/* <a href="#" className="btn btn-custom">
                    {languageStrings['Learn More']}
                  </a> */}
                </div>
              </div>
            </div>
          </div>

          <div
            className="row align-items-center"
            style={{ marginBottom: "80px" }}
          >
            <div className="col-lg-6 col-dash-6">
              <div>
                <div className="content mb-5">
                  <div className="ListCount mb-4">
                    <h3 className="List">03</h3>
                  </div>
                  <h4 className="blueheadingtwop mb-3">{languageStrings['Traditional Finance']} </h4>
                  <p className="description mb-5">
                    {languageStrings['One of the primary challenges']}
                  </p>
                  {/* <a href="#" className="btn btn-custom">
                    {languageStrings['Learn More']}
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-dash-6">
              <div>
                <div className="banner ">
                  <img
                    src="/assets/images/finance.png"
                    alt=""
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
