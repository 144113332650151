import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import {
  getLanguageCode,
  getSupportedLanguages,
  i18n,
  setLanguage
} from 'web-translate';
import { useEffect, useState } from "react";
import languageStrings from '../../language/index';
import languageDictionary from '../../language/languages.json';

export default function  Header() {

  const [ languages, setLanguages ] = useState([]);

  useEffect(() => {
    (async () => {
      const lang = await getSupportedLanguages();
    })()
  }, [])

  return (
    <>
      <Navbar className="custom-nav" bg="light" expand="lg">
        <Container>
          <Navbar.Brand>
            <img  style={{width:"79px"}} src="/assets/imgs/logo.png" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />

          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="mx-auto my-2 my-lg-0"
              navbarScroll
            >
              <Nav.Link className="active">{languageStrings['Platform']}</Nav.Link>
              <Nav.Link href="#sale-section">{languageStrings['Estate Upcare coin']}</Nav.Link>
              <Nav.Link href="#how-to-buy">{languageStrings['How to Buy']}</Nav.Link>
              <Nav.Link href="#road-map">{languageStrings['Roadmap']}</Nav.Link>
              <Nav.Link href="#faq">{languageStrings['FAQ']}</Nav.Link>
            </Nav>
            <ul className="d-flex list-inline m-0 align-items-center">
              <li>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {(localStorage.getItem('lang') || 'en').toUpperCase()}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {
                      Object.keys(languageDictionary).map(key => (
                        <Dropdown.Item onClick={()=> {
                          localStorage.setItem('lang', key);
                          window.location.reload();
                        }}>{languageDictionary[key].language} ({key.toUpperCase()})</Dropdown.Item>
                      ))
                    }
                    {/* <Dropdown.Item onClick={()=> {
                      localStorage.setItem('lang', 'EN');
                      window.location.reload();
                    }}>EN</Dropdown.Item>
                    <Dropdown.Item onClick={()=> {
                      localStorage.setItem('lang', 'ar');
                      window.location.reload();
                    }}>AR</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="socialLinks">
                <a href="">
                  <img src="/assets/images/linkedin.png" />
                </a>
              </li>
              {/* <li className="socialLinks">
                <a href="">
                  <img src="/assets/images/twitter.png" />
                </a>
              </li>
              <li className="socialLinks">
                <a href="">
                  <img src="/assets/images/linkedin.png" />
                </a>
              </li>
              <li className="socialLinks">
                <a href="">
                  <img src="/assets/images/instagram.png" />
                </a>
              </li> */}
            </ul>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
