import languageStrings from "../../language/index";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              {" "}
              <div className="footer-img" style={{ marginBottom: "20px" }}>
                <img
                  className=""
                  style={{ width: "79px" }}
                  src="/assets/imgs/logo.png"
                />
              </div>
              <p
                style={{ color: "white", padding: "10px 0px" }}
                className="text-md-start text-center"
              >
                ESTATE UPCARE COIN CRYPTO BACKED BY REAL ESTATE, INFRASTRUCTURE,
                CONSTRUCTION
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-md-start text-center">
              <ul className="footer-ul">
                <li className="">
                  <a href="#platform">{languageStrings["Platform"]}</a>
                </li>
                <li className="">
                  <a href="#sale-section">
                    {languageStrings["Estate Upcare Coin"]}
                  </a>
                </li>
                <li className="">
                  <a href="#how-to-buy">{languageStrings["How to Buy"]}</a>
                </li>
                <li className="">
                  <a href="#road-map">{languageStrings["Roadmap"]}</a>
                </li>

                <li className="">
                  <a href="#faq">{languageStrings['FAQ']}</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6  col-sm-6 col-12  text-md-start text-center">
              <ul className="social-links">
                <li>
                  <a className="" style={{
                      color: "white",
                      textDecoration: "none"
                    }}
                    href="/assets/policy/Estate-Upcare-Privacy-Policy.pdf" target="_blank">
                    {languageStrings["Privacy Policy"]}
                  </a>
                </li>
                <li className="socialLinks mr-2">
                  <a
                    style={{
                      color: "white",
                      textDecoration: "none",
                    
                    }}
                    href="/assets/policy/Estate-Upcare-Disclaimer.pdf" target="_blank">
                    {languageStrings["Disclaimer"]}
                  </a>
                </li>
                <li className="socialLinks">
                  <a
                    style={{
                      color: "white",
                      textDecoration: "none",
                      
                    }}
                    href="/assets/policy/Estate-Upcare-Cookies-policy.pdf" target="_blank">

                    {languageStrings["cookies"]}
                  </a>
                </li>
                <li className="socialLinks">
                  <a
                    style={{
                      color: "white",
                      textDecoration: "none",
                    
                    }}
                    href="/assets/policy/Estate-Upcare-Terms_Conditions.pdf" target="_blank">

                    {languageStrings["Terms & Conditions"]}
                  </a>
                </li>
              </ul>
              {/* <div className="d-flex  align-items-center policy  text-center flex-wrap justify-content-center">
                
            
              </div> */}
            </div>
            <hr className="footer-divider" />
            <div className="container footer-text">
              <div className="row">
                <div className="col-lg-12">
                  <p className="text-center">
                    <span>&copy;</span>{" "}
                    {languageStrings["Copyright 2023, All Rights Reserved"]}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
