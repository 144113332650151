import languageStrings from '../../../language/index';

export default function AboutUs() {

  return (
    <>
      <section id="about-us" className="AboutUs d-flex align-items-center position-relative">
        <div className="anim1">
          <img
            src="/assets/images/abouticon.png"
            className="avatar position-absolute"
            style={{ top: "10%", left: "10%" }}
            alt=""
          />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-lg-md-4 col-dash-4  order-2 order-md-1">
              <div className="mb-5">
                <div className="banner">
                  <img
                    src="/assets/images/aboutus.png"
                    alt=""
                    className="w-100"
                    style={{marginTop:"30px"}}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-8  col-lg-md-8 col-dash-8 order-1 order-md-2">
              <div>
                <div className="content">
                  <h4 className="whiteheadingtwo mb-3">{languageStrings['About us']}</h4>
                  <p className="description mb-5">
                    {languageStrings['Estate Upcare aims to create']}
                  </p>
                  <a href="/assets/whitepaper/Estate Upcare.pdf" target="_blank" className="btn btn-custom">
                    {languageStrings['Whitepaper']}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}