import React, { useState } from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import languageStrings from '../../language/index';

const Sidebar = () => {

  const router = useRouteMatch();

  return (
    <div>
      <Link id="navigate-purchase-token" to="/dashboard/purchase-token" style={{ display: 'none' }} />
      <Link id="navigate-transaction" to="/dashboard/transaction" style={{ display: 'none' }} />
      <Link id="navigate-to-home" to="/" style={{ display: 'none' }} />
      <div className="navigation">
        <a onClick={(e) => {
          e.preventDefault();
          document.getElementById('navigate-to-home').click();
        }} className="brand">
          <img src="/assets/imgs/dashlogo.png" alt="dashlogo" />
        </a>
        <div className="buy" style={{cursor:"pointer"}}>
          {languageStrings['Buy']}
        </div>
        <div className={`links ${router.params.id === "purchase-token" ? "isActive" : ""}`} onClick={() => document.getElementById("navigate-purchase-token").click()} style={{cursor:"pointer"}} >
          <img src="/assets/imgs/coin.png" alt="coin" />
          <p>{languageStrings['Purchase Coin']}</p>
        </div>
        <div className={`links ${router.params.id === "transaction" ? "isActive" : ""}`} onClick={() => document.getElementById("navigate-transaction").click()} style={{cursor:"pointer"}}>
          <img src="/assets/imgs/transaction.png" alt="coin" />
          <p>{languageStrings['Transaction History']}</p>
        </div>
        {/* Rest of the sidebar content */}
        {/* {activeLink === "purchaseCoin" && <PurchaseToken />}
        {activeLink === "transaction" && <Transactiondetails />} */}
      </div>
    </div>
  );
};

export default Sidebar;