import languageStrings from '../../../language/index';

export default function Buy() {

  return (
    <>
      <section id="how-to-buy" className="Buy position-relative">
        <div className="anim1">
          <img
            src="/assets/images/buyicon.png"
            className="avatar position-absolute"
            style={{ top: "10%", left: "10%" }}
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-dash-12 text-center ">
              <div>
                <h4 className="whiteheadingtwoc mb-2">{languageStrings['How to Buy']}</h4>
                <p className="description mb-5">
                  {languageStrings['The Estate Upcare token']}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-dash-4">
              <div>
                <div className="content content-card mb-4">
                  <div className="Box">
                    <img src="/assets/images/bigcoin.png" className="mb-4" alt="" />
                    <h4 className="blueheadingtwoc mb-3">{languageStrings['Use ETH and BNB']}</h4>
                    <p className="description mb-0">
                      {languageStrings['If you have ETH or BNB']}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-dash-4" >
              <div>
                <div className="content content-card mb-4">
                  <div className="Box">
                    <img src="/assets/images/usdt.png" className="mb-4" alt="" />
                    <h4 className="blueheadingtwoc mb-3">{languageStrings['Use USDT']}</h4>
                    <p className="description mb-0">
                      {languageStrings['If you have USDT']}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-dash-4">
              <div>
                <div className="content content-card mb-4">
                  <div className="Box">
                    <img src="/assets/images/card.png" className="mb-4" alt="" />
                    <h4 className="blueheadingtwoc mb-3">{languageStrings['Use your card']}</h4>
                    <p className="description mb-0">
                      {languageStrings['If you have card']}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
