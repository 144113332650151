import GifSlider from "./section/GifSlider";
import Sale from "./section/Sale";
import AboutUs from "./section/AboutUs";
import Problems from "./section/Poblems";
import Solutions from "./section/Solutions";
import Buy from "./section/Buy";
import RoadMap from "./section/Roadmap";
import Team from "./section/Team";
import Partners from "./section/Partners";
import FAQ from "./section/FAQ";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import './home.css';

export default function Home() {

  return (
    <>
      <Header />
      <GifSlider />
      <Sale />
      <AboutUs />
      <Problems />
      <Solutions />
      <Buy />
      <RoadMap />
      {/* <Team /> */}
      <Partners />
      <FAQ />
      <Footer />
    </>
  );
}
