import Home from "./component/home/Home";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Dashboard from "./component/dashboard/Dashboard";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Buffer } from 'buffer';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { mainnet, sepolia, bsc, bscTestnet } from 'wagmi/chains'
import AddressSignChangeListener from "./component/layout/AddressSignChangeListener";

window.Buffer = window.Buffer || Buffer;

const chains = [ mainnet, bsc, sepolia ];
const projectId = process.env.REACT_APP_PROJECT_ID;

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})

const ethereumClient = new EthereumClient(wagmiConfig, chains)

function App() {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
    <ToastContainer />
      <WagmiConfig config={wagmiConfig}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/dashboard/:id(purchase-token|payment|transaction)" component={Dashboard}/>
        </Switch>
      </BrowserRouter>
      <AddressSignChangeListener />
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
}

export default App;
