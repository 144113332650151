import isNumber from 'is-number';

const getNetworkByChainId = (chainId) => {
    // if(chainId == 1 || chainId == 11155111 || chainId == 2 || chainId == 3 || chainId == 4) return "Ethereum"
    if(chainId == 56 || chainId == 97) return 'Binance (BNB)';

    return 'Ethereum'
}

const getNetworkSymbolByChainId = (chainId) => {
    // if(chainId == 1 || chainId == 11155111 || chainId == 2 || chainId == 3 || chainId == 4) return "Ethereum"
    if(chainId == 56 || chainId == 97) return 'BNB';

    return 'ETH'
}

export const isSupportedChain = (networkId) => networkId == 11155111 || networkId == 1 || networkId == 56 || networkId == 97

// const _isNumber =  (value) => !/[a-b]|[A-Z]|[$&+,:;=?@#|'<>^*()%!-]/.test(value);
const _isNumber =  (value) => isNumber(value);

export const isBnb =  (id) => id == 56 || id == 97;
export const isEth =  (id) => id == 1 || id == 11155111 || id == 97;

export default {
    getNetworkByChainId,
    isNumber: _isNumber,
    getNetworkSymbolByChainId,
    isBnb,
    isEth
}